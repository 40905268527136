<template>
  <div class="payment-history-vue">
    <Header>
      <template v-slot:title>
        {{ $t("payment-history-91a1bd08a417cbd1ce9ee50d8c67bab6") }}
      </template>
    </Header>
    <div
      class="bg-white shadow rounded-md min-h-screen m-auto max-w-7xl p-0 sm:mt-5"
    >
      <div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="align-middle inline-block min-w-full">
              <div class="overflow-hidden border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        v-if="
                          whitelabel === 'TD365' ||
                          whitelabel === 'TDSouthAfrica'
                        "
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        v-else
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        #
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Type") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Account") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Date") }}
                      </th>
                      <th
                        v-if="whitelabel !== 'OrbitInvest'"
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("payment-method") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Amount") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Status") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    v-if="whitelabel === 'OrbitInvest'"
                    class="bg-white divide-y divide-gray-200"
                  >
                    <tr
                      v-for="(payment, index) in get_transaction_history"
                      :key="index"
                    >
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.id }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.type }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.trading_account }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.date | moment("DD-MM-YYYY  HH:mm") }}
                      </td>
                      <!--  <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                       <span v-if="check(payment.method)">
                         {{ $t(payment.method.replace(/\s/g, '-').toLowerCase()) }}
                       </span>
                       <span v-else>
                         {{ payment.method }}
                       </span> 
                     </td> -->
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="payment.currency"
                          :value="payment.value"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <svg
                          v-if="payment.success"
                          class="w-5 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 13l4 4L19 7"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                        <svg
                          v-else
                          class="w-5 text-red-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="(payment, index) in get_payment_history"
                      :key="index"
                    >
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.id }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ type[payment.type] }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.trading_account }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ payment.date | moment("DD-MM-YYYY  HH:mm") }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <span v-if="check(payment.method)">
                          {{
                            $t(payment.method.replace(/\s/g, "-").toLowerCase())
                          }}
                        </span>
                        <span v-else>
                          {{ payment.method }}
                        </span>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="payment.currency"
                          :value="payment.value"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <svg
                          v-if="payment.success"
                          class="w-5 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 13l4 4L19 7"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                        <svg
                          v-else
                          class="w-5 text-red-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Header from "@/components/Header";
import i18next from "i18next";

export default {
  name: "PaymentHistory",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    "money-format": MoneyFormat,
    Header,
  },
  computed: {
    ...mapGetters([
      "get_payment_history",
      "get_transaction_history",
      "get_profile",
      "get_uploaded_documents",
    ]),
    type() {
      const t = this.$t.bind(this);
      return {
        Deposit: t("Deposit"),
        Withdraw: t("Withdraw"),
      };
    },
  },
  created() {
    if (this.whitelabel === "OrbitInvest") {
      this.$store.dispatch("get_transaction_history");
    } else {
      this.$store.dispatch("get_payment_history");
    }
  },
  methods: {
    check(item) {
      return i18next.exists(item.replace(/\s/g, "-").toLowerCase());
    },
  },
};
</script>